import { User } from "../types";
import { Timestamp } from "firebase/firestore";

class UserImpl implements User {
  uid: string;
  name: string;
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string | null;
  invites: Array<string>;
  created: Timestamp;
  updated: Timestamp;
  lastLogin: Timestamp;

  constructor(
    uid: string,
    name: string,
    displayName: string | null,
    email: string | null,
    phoneNumber: string | null,
    photoURL: string | null,
    providerId: string | null,
    invites: Array<string>,
    created: Timestamp,
    updated: Timestamp,
    lastLogin: Timestamp
  ) {
    this.uid = uid;
    this.name = name;
    this.displayName = displayName;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.photoURL = photoURL;
    this.providerId = providerId;
    this.invites = invites ? invites : [];
    this.created = created;
    this.updated = updated;
    this.lastLogin = lastLogin;
  }
}

export default UserImpl;
