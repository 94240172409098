import { Container, Row, Spinner } from "react-bootstrap";
import React from "react";

const Loading: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="vertical-center">
      <Container>
        <Row className="mt-4">
          <div className={"text-center"}>
            <Spinner animation="border" />
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Loading;
