import {
  AnalyticsProvider,
  AppCheckProvider,
  AuthProvider,
  FirestoreProvider,
  SuspenseWithPerf,
  useInitAnalytics,
  useInitAppCheck,
  useInitAuth,
  useInitFirestore,
  useInitPerformance,
} from "reactfire";
import React from "react";
import Loading from "./components/Loading";
import { ReCaptchaV3Provider } from "firebase/app-check";

const FirebaseComponents: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const APP_CHECK_TOKEN = "6Lcm7k8dAAAAAAT3MLRijYsKCE5gXiRpWo-FaG0Y";

  const { data: appCheck } = useInitAppCheck(async (firebaseApp) => {
    const { initializeAppCheck } = await import(
      "firebase/app-check" /* webpackChunkName: "appcheck" */
    );
    return initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
      isTokenAutoRefreshEnabled: false,
    });
  });

  const { data: firestore } = useInitFirestore(async (app) => {
    const { initializeFirestore } = await import(
      "firebase/firestore" /* webpackChunkName: "firestore" */
    );
    return initializeFirestore(app, {});
  });

  const { data: analytics } = useInitAnalytics(async (app) => {
    const { getAnalytics } = await import(
      "firebase/analytics" /* webpackChunkName: "analytics" */
    );
    return getAnalytics(app);
  });

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import(
      "firebase/performance" /* webpackChunkName: "performance" */
    );
    return getPerformance(firebaseApp);
  });

  const { data: auth } = useInitAuth(async (firebaseApp) => {
    const { getAuth } = await import(
      "firebase/auth" /* webpackChunkName: "auth" */
    );
    return getAuth(firebaseApp);
  });

  return (
    <SuspenseWithPerf fallback={<Loading />} traceId={"loading-appcheck"}>
      <AppCheckProvider sdk={appCheck}>
        <SuspenseWithPerf fallback={<Loading />} traceId={"loading-auth"}>
          <AuthProvider sdk={auth}>
            <SuspenseWithPerf
              fallback={<Loading />}
              traceId={"loading-firestoreprovider"}
            >
              <FirestoreProvider sdk={firestore}>
                <SuspenseWithPerf
                  fallback={<Loading />}
                  traceId={"loading-analytics"}
                >
                  <AnalyticsProvider sdk={analytics}>
                    {children}
                  </AnalyticsProvider>
                </SuspenseWithPerf>
              </FirestoreProvider>
            </SuspenseWithPerf>
          </AuthProvider>
        </SuspenseWithPerf>
      </AppCheckProvider>
    </SuspenseWithPerf>
  );
};

export default FirebaseComponents;
