import { User as FireBaseUser } from "@firebase/auth";
import { logEvent, setUserId } from "firebase/analytics";
import {
  GoogleAuthProvider,
  signInWithPopup,
  UserCredential
} from "firebase/auth";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc
} from "firebase/firestore";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAnalytics, useAuth, useFirestore, useSigninCheck } from "reactfire";
import { userConverter } from "./common/Converters";
import { MessageType } from "./common/enum";
import UserImpl from "./common/UserImpl";
import Loading from "./components/Loading";
import { User } from "./types";
import { sendMessageRaw } from "./UidContextProvider";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    importance?: "auto" | "high" | "low";
  }
}

const initialUser = (fireBaseUser: FireBaseUser): User => {
  return new UserImpl(
    fireBaseUser.uid,
    fireBaseUser
      ? fireBaseUser.displayName
        ? fireBaseUser.displayName
        : fireBaseUser.email
        ? fireBaseUser.email
        : "onbekend"
      : "onbekend",
    fireBaseUser.displayName,
    fireBaseUser.email,
    fireBaseUser.phoneNumber,
    fireBaseUser.photoURL,
    fireBaseUser.providerData != null && fireBaseUser.providerData.length > 0
      ? fireBaseUser.providerData[0].providerId
      : null,
    [],
    Timestamp.now(),
    Timestamp.now(),
    Timestamp.now()
  );
};

const Login: React.FC<
  React.PropsWithChildren<{ children?: React.ReactNode }>
> = ({ children }) => {
  const {
    status: useSigninStatus,
    data: signInCheckResult,
    error,
  } = useSigninCheck();
  const analytics = useAnalytics();
  const auth = useAuth();
  const firestore = useFirestore();

  const login = (userCredential: UserCredential): void => {
    const user = userCredential.user;

    setUserId(analytics, user.uid);

    const userRef = doc(firestore, "users", user.uid).withConverter(
      userConverter
    );

    getDoc(userRef)
      .then((snapShot) => {
        if (!snapShot.exists()) {
          logEvent(analytics, "sign_up");
          setDoc(userRef.withConverter(userConverter), initialUser(user))
            .then(() => {
              toast.info("Welkom nieuwe gebruiker " + user.displayName);
            })
            .catch((err: Error) => toast.error(err.name + " : " + err.message));
        } else {
          logEvent(analytics, "login");

          const user: User = snapShot.data();

          toast.info("Welkom " + user.name);

          sendMessageRaw(firestore, user.uid, MessageType.login, user.invites);

          updateDoc(userRef, {
            lastLogin: serverTimestamp(),
            updated: serverTimestamp(),
          }).catch((err: Error) => {
            toast.error(err.name + " : " + err.message);
          });
        }
      })
      .catch((err: Error) => toast.error(err.name + " : " + err.message));
  };

  const signIn = (): Promise<void> => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .then(login)
      .catch((err: Error) =>
        toast.error(err.name + " : " + err.message)
      ) as Promise<void>;
  };

  const SignInButtons: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
      <div className="vertical-center">
        <Container>
          <Row>
            <Col md={{ offset: 2, span: 8 }} className={"text-center"}>
              <img
                src={"/logo512.png"}
                style={{ height: "30vh", maxHeight: "400px" }}
                alt={"logo"}
                importance="high"
                draggable="false"
              />
              <h3>Klavers Jassen</h3>
              {error !== undefined ? (
                <h5 style={{ color: "red" }}>
                  {error.name}:{error.message}
                </h5>
              ) : (
                <></>
              )}
              {useSigninStatus === "loading" ? (
                <Loading />
              ) : (
                <Button onClick={() => signIn()}>Login met Google</Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  return signInCheckResult.signedIn ? <>{children}</> : <SignInButtons />;
};

export default Login;
