export const firebaseConfig = {
  apiKey: "AIzaSyC7z_5E15KS40s8vfCK2reTjoKUmWYxluM",
  authDomain: "klaversjassen.firebaseapp.com",
  databaseURL:
    "https://klaversjassen-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "klaversjassen",
  storageBucket: "klaversjassen.appspot.com",
  messagingSenderId: "316752448211",
  appId: "1:316752448211:web:aaf989cf282b810c0a6226",
  measurementId: "G-6M8LL92T39",
};

export default firebaseConfig;
