import { logEvent } from "firebase/analytics";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  Firestore,
  serverTimestamp,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import React from "react";
import { toast } from "react-toastify";
import { useAnalytics, useFirestore, useUser } from "reactfire";
import { messageConverter } from "./common/Converters";
import { MessageType } from "./common/enum";
import Loading from "./components/Loading";
import { Message } from "./types";

interface UidContextProps {
  uid: string;
  deleteUser: (deleterUid: string) => Promise<void>;
  addFeedBack: (comment: string) => Promise<void>;
  addFriend: (adder: string) => Promise<void>;
  delFriend: (remover: string) => Promise<void>;
  sendMessage: (
    messageType: MessageType,
    receivers: string[],
    body?: string
  ) => Promise<void>;
}

const UidContext = React.createContext<UidContextProps>({
  uid: "",
  deleteUser: () => Promise.resolve(),
  addFeedBack: () => Promise.resolve(),
  addFriend: () => Promise.resolve(),
  delFriend: () => Promise.resolve(),
  sendMessage: () => Promise.resolve(),
});

export const sendMessageRaw = (
  firestore: Firestore,
  uid: string,
  messageType: MessageType,
  receivers: string[],
  body?: string
): Promise<void> => {
  const batch = writeBatch(firestore);

  receivers.forEach((receiver) => {
    const message: Message = {
      created: Timestamp.now(),
      creator: uid,
      messageType: messageType,
      receiver: receiver,
      read: false,
      body: body,
    };

    batch.set(
      doc(collection(firestore, "messages")).withConverter(messageConverter),
      message
    );
  });

  return batch
    .commit()
    .catch((err: Error) =>
      toast.error(err.name + " : " + err.message)
    ) as Promise<void>;
};

export const UidContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const firestore = useFirestore();
  const analytics = useAnalytics();

  const { data: fireBaseUser, status } = useUser();

  if (status === "loading") {
    return <Loading />;
  }

  if (!fireBaseUser) {
    return <>no user</>;
  }

  const uid = fireBaseUser.uid;

  const deleteUser = (deleterUid: string): Promise<void> => {
    return deleteDoc(doc(firestore, "users", deleterUid));
  };

  const addFeedBack = (comment: string): Promise<void> => {
    return addDoc(collection(firestore, "feedback"), {
      creator: uid,
      created: serverTimestamp(),
      comment: comment,
    }).catch((err) => {
      toast.error(err.message);
    }) as Promise<void>;
  };

  const addFriend = (adder: string): Promise<void> => {
    return updateDoc(doc(firestore, "users", uid), {
      updated: serverTimestamp(),
      invites: arrayUnion(adder),
    })
      .then(() => {
        logEvent(analytics, "join_group");
        sendMessage(MessageType.invitation, [adder]);
      })
      .catch((err: Error) =>
        toast.error(err.name + " : " + err.message)
      ) as Promise<void>;
  };

  const delFriend = (remover: string): Promise<void> => {
    return updateDoc(doc(firestore, "users", uid), {
      updated: serverTimestamp(),
      invites: arrayRemove(remover),
    }).catch((err: Error) =>
      toast.error(err.name + " : " + err.message)
    ) as Promise<void>;
  };

  const sendMessage = (
    messageType: MessageType,
    receivers: string[],
    body?: string
  ): Promise<void> => {
    return sendMessageRaw(firestore, uid, messageType, receivers, body);
  };

  return (
    <UidContext.Provider
      value={{
        uid: uid,
        deleteUser: deleteUser,
        addFeedBack: addFeedBack,
        addFriend: addFriend,
        delFriend: delFriend,
        sendMessage: sendMessage,
      }}
    >
      {children}
    </UidContext.Provider>
  );
};

export default UidContext;
