import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  serverTimestamp,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";
import {
  Choice,
  Feedback,
  GameState,
  Message,
  PlayerCard,
  User,
} from "../types";
import { Suit } from "./enum";
import GameStateImpl from "./GameStateImpl";
import UserImpl from "./UserImpl";

export const feedbackConverter: FirestoreDataConverter<Feedback> = {
  toFirestore(feedback: WithFieldValue<Feedback>): DocumentData {
    return {
      created: serverTimestamp(),
      creator: feedback.creator,
      comment: feedback.comment,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot): Feedback {
    const data = snapshot.data();

    return {
      id: snapshot.id,
      created: data.created,
      creator: data.creator,
      comment: data.comment,
    } as Feedback;
  },
};

export const messageConverter: FirestoreDataConverter<Message> = {
  toFirestore(message: WithFieldValue<Message>): DocumentData {
    let undefinedKeys = {};

    if (message.body) {
      undefinedKeys = { body: message.body };
    }

    return {
      ...{
        created: serverTimestamp(),
        creator: message.creator,
        messageType: message.messageType,
        receiver: message.receiver,
        read: message.read,
      },
      ...undefinedKeys,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot): Message {
    const data = snapshot.data();

    return {
      created: data.created,
      creator: data.creator,
      messageType: data.messageType,
      receiver: data.receiver,
      read: data.read === undefined ? false : data.read,
      body: data.body,
    };
  },
};

export const userConverter: FirestoreDataConverter<User> = {
  toFirestore: function (user: WithFieldValue<User>): DocumentData {
    return {
      uid: user.uid,
      name: user.name,
      displayName: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      providerId: user.providerId,
      invites: user.invites,
      created: serverTimestamp(),
      updated: serverTimestamp(),
      lastLogin: serverTimestamp(),
    };
  },

  fromFirestore: function (snapshot: QueryDocumentSnapshot): User {
    const data = snapshot.data();

    return new UserImpl(
      snapshot.id as string,
      data.name as string,
      data.displayName as string,
      data.email as string,
      data.phoneNumer as string,
      data.photoURL as string,
      data.providerId as string,
      data.invites as string[],
      data.created as Timestamp,
      data.updated ? data.updated : (data.created as Timestamp),
      data.lastLogin ? data.lastLogin : (data.created as Timestamp)
    );
  },
};

export const gameStateConverter: FirestoreDataConverter<GameState> = {
  toFirestore: function (gameState: WithFieldValue<GameState>): DocumentData {
    let undefinedKeys = {};

    if (gameState.elder) {
      undefinedKeys = { ...{ elder: gameState.elder }, undefinedKeys };
    }

    return {
      ...{
        created: serverTimestamp(),
        updated: serverTimestamp(),
        creator: gameState.creator,
        playerCard: (gameState.playerCard as Array<PlayerCard>).map((pc) => ({
          player: pc.player,
          card: pc.card,
        })),
        players: gameState.players,
        dealer: gameState.dealer,
        turns: gameState.turns,
        trump: gameState.trump,
        ended: gameState.ended,
        choices: gameState.choices,
      },
      ...undefinedKeys,
    };
  },

  fromFirestore: function (snapshot: QueryDocumentSnapshot): GameState {
    const data = snapshot.data();

    return new GameStateImpl(
      snapshot.id as string,
      data.created as Timestamp,
      data.updated as Timestamp,
      data.creator as string,
      data.dealer as number,
      (data.playerCard as { player: number; card: string }[]).map(
        (pc) =>
          ({
            player: pc.player,
            card: pc.card,
          } as PlayerCard)
      ) as PlayerCard[],
      data.players as string[],
      data.turns ? data.turns : [],
      data.trump as Suit,
      data.ended as boolean,
      data.elder as number | undefined,
      data.choices === undefined ? [] : (data.choices as Choice[])
    );
  },
};
