import React from "react";
import { SuspenseWithPerf } from "reactfire";
import Loading from "./components/Loading";
import FirebaseComponents from "./FirebaseComponents";
import Login from "./Login";

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const AuthenticatedApp = React.lazy(
    () =>
      import("./AuthenticatedApp" /* webpackChunkName: "AuthenticatedApp" */)
  );

  // disable pinch zoom
  // document.addEventListener(
  //   "touchstart",
  //   function (event) {
  //     event.preventDefault();
  //   },
  //   { passive: false }
  // );

  return (
    <FirebaseComponents>
      <SuspenseWithPerf fallback={<Loading />} traceId={"loading-login"}>
        <Login>
          <SuspenseWithPerf
            fallback={<Loading />}
            traceId={"loading-AuthenticatedApp"}
          >
            <AuthenticatedApp />
          </SuspenseWithPerf>
        </Login>
      </SuspenseWithPerf>
    </FirebaseComponents>
  );
};

export default App;
