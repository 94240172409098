export enum Suit {
  Clubes,
  Hearts,
  Spades,
  Diamonds,
}

export enum MessageType {
  login,
  lastTrick,
  startGame,
  invitation,
  gameMessage,
  yawn,
  go,
  roem,
  verzaken,
}
