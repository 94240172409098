import "bootstrap-icons/font/bootstrap-icons.css";
import "bootswatch/dist/cyborg/bootstrap.min.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { Flip, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { FirebaseAppProvider, SuspenseWithPerf } from "reactfire";
import App from "./App";
import Loading from "./components/Loading";
import firebaseConfig from "./firebaseConfig";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Config, register } from "./serviceWorkerRegistration";

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("no root element");
}

createRoot(rootElement).render(
  <React.StrictMode>
    <SuspenseWithPerf
      fallback={<Loading />}
      traceId={"loading-FirebaseAppProvider"}
    >
      <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
        <SuspenseWithPerf fallback={<Loading />} traceId={"loading-app"}>
          <App />
        </SuspenseWithPerf>
      </FirebaseAppProvider>
    </SuspenseWithPerf>
    <ToastContainer
      position="top-right"
      autoClose={7500}
      hideProgressBar={false}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover
      theme={"dark"}
      transition={Flip}
    />
  </React.StrictMode>
);

const config1: Config = {
  onUpdate: (registration: ServiceWorkerRegistration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", () => {
        if (waitingServiceWorker.state === "activated") {
          toast.warn("Updating...");
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
};

// const config2: Config = {
//     onUpdate: (registration) => {
//         if (registration && registration.waiting) {
//             registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//             window.location.reload();
//         }
//     }
// }

register(config1);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
